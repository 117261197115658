<template>
  <nav class="navbar navbar-expand-lg bg-primary">
    <div class="container-fluid">
      <NuxtLink to="/admin">
        <CustomPicture
          class="px-3 py-3"
          imagem="logo-sorrifacil-branco-pt"
          width="250"
          height="100"
        />
      </NuxtLink>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse d-flex justify-content-center"
        id="navbarNavDropdown"
      >
        <ul class="navbar-nav me-right mb-2 mb-lg-0">
          <span v-for="menu in menus" v-bind:key="menu?.link">
            <li class="nav-item mx-3">
              <NuxtLink class="nav-link" aria-current="page" :to="menu.link">
                {{ menu.texto }}
              </NuxtLink>
            </li>
          </span>
        </ul>
      </div>

      <form class="d-flex" role="search">
        <button
          class="btn btn-outline-primary text-white"
          @click="handleSair()"
        >
          Sair
        </button>
      </form>
    </div>
  </nav>
</template>

<script setup>
const emit = defineEmits(["onSair"]);
const props = defineProps({
  menus: Array,
});

const handleSair = () => {
  emit("onSair");
};
</script>

<script>
export default {
  name: "AdminMenu",
};
</script>
