<template>
  <div v-if="(status = 'success')">
    <AdminMenu :titulo="titulo" :menus="menu" @onSair="handleSair" />

    <div class="px-5 py-5">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
const titulo = ref("Site Admin");
const token = useState("token");
const { data: menu, status } = useFetch("/api/admin/menu", {
  headers: { authorization: token.value },
});

const handleSair = async () => {
  const config = useRuntimeConfig();
  const token = useCookie(config.public.cookieName);
  token.value = null;
  await navigateTo("/admin/login");
};

useHead({
  link: [
    {
      href: "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css",
      rel: "stylesheet",
      integrity:
        "sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65",
      crossorigin: "anonymous",
    },
  ],
  script: [
    {
      src: "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js",
      integrity:
        "sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4",
      crossorigin: "anonymous",
    },
  ],
});
</script>

<script>
export default {
  name: "AdminLayout",
};
</script>
